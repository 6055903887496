import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 90px 0",
	"sm-padding": "40px 0",
	"min-height": "600px",
	"sm-min-height": "auto",
	"display": "flex",
	"lg-padding": "30px 0 60px 0",
	"overflow-x": "hidden",
	"overflow-y": "hidden",
	"quarkly-title": "Advantages/Features-9"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "45%",
			"flex-direction": "column",
			"justify-content": "center",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-align-items": "flex-start",
			"lg-margin": "0px 0px 60px 0px",
			"sm-margin": "0px 0px 40px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "24px 0px 16px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"sm-width": "100%",
			"md-margin": "0px 0px 30px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px",
			"font": "normal 600 40px/1.5 --fontFamily-sans",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"md-margin": "0px 0px 32px 0px",
			"md-padding": "0px 0px 0px 0px",
			"sm-font": "normal 600 40px/1.2 --fontFamily-sans",
			"lg-text-align": "center",
			"children": "Зв'яжіться з нами для отримання додаткової інформації"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "20px 0 0 0",
			"font": "--lead",
			"font-size": "20px",
			"font-weight": "300",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"md-margin": "0px 0px 0px 0px",
			"md-padding": "0px 0px 0px 0px",
			"sm-font": "--base",
			"lg-text-align": "center",
			"children": "Щоб дізнатися більше про наші послуги або записатися на гру, будь ласка, зв'яжіться з нами. Наша команда надасть вам детальну інформацію та кваліфіковану консультацію."
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"color": "--lightD1",
			"text-decoration-line": "initial",
			"padding": "7px 18px 9px 18px",
			"background": "--color-primary",
			"font": "--base",
			"transition": "--opacityOut",
			"hover-opacity": ".7",
			"border-radius": "6px",
			"letter-spacing": "0.5px",
			"margin": "30px 0px 0px 0px",
			"children": "Контакти"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"justify-content": "flex-start",
			"lg-justify-content": "center",
			"lg-width": "100%",
			"padding": "0px 0px 0px 16px",
			"align-items": "center",
			"lg-padding": "0px 0px 0px 0px",
			"font": "20px sans-serif"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "972px",
			"height": "589px",
			"md-display": "flex",
			"md-justify-content": "center",
			"lg-height": "auto"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"height": "589px",
			"box-shadow": "0 10px 20px -10px rgba(43, 45, 84, 0.15),0 0px 20px 0 rgba(43, 45, 84, 0.05)",
			"lg-width": "100%",
			"border-radius": "8px",
			"z-index": "9",
			"position": "relative",
			"lg-margin": "0px 0px 0px 0px",
			"src": "https://ua.pixvos.com/img/8.jpg",
			"display": "block",
			"object-fit": "cover",
			"lg-height": "auto",
			"max-width": "900px",
			"width": "100% ",
			"object-position": "30%"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			flex-wrap="wrap"
			grid-gap="5%"
			sm-min-width="280px"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Image {...override("image")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;